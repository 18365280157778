<template>
  <div class="home-container">
    <div class="div_img">
      <img src="../../assets/img/czj/step1.png" />

    </div>
    <div class="div_img">
      <img src="../../assets/img/czj/step2.png" />

    </div>
    <div class="div_img">
      <img src="../../assets/img/czj/step3.png" />

    </div>
    <div style="text-align: center;
    color: #fff;
    font-weight: 700;
    font-size: 0.5rem;margin: 0.3rem;">
      —&nbsp;领劵说明&nbsp;—

    </div>
    <div style="    width: 95%;
    margin: 0 auto;
    background: #fff;
    padding: 0.3rem;
    font-size: 0.35rem;
    box-sizing: border-box;
    border-radius: 0.5rem;">
      <P>
        兑换特惠话费充值券后，仅可在掌讯信息小程序中充值使用。
      </P>
      <p>
        <span style="color:#FF4E6B">**重要通知:</span>尊敬的用户您好！由于与生活权益合作的三方充值渠道最近充值失败率过高，造成了很不好的使用体验。现决定停止新用户的充值券领取，老用户依然可以领取充值券，但目前充值渠道依然不稳定，老用户请慎重领取！老用户发生退订后，将不再被认定为老用户。
      </p>
      <P>
        1，充值券仅限给领券手机号进行充值，不可给其他手机充值
      </P>
      <p>
        2，领券成功后，需在微信搜索“ 移动”公众号，关注公众号后点击“权益 | 福利--滨州生活”进入优惠券激活页面，点击要激活的优惠券进行激活操作。激活后点击“去使用”按钮跳转到掌讯小程序进行充值，充值满100即可使用本优惠券。领券后需在15日内激活，激活后需在30天内使用，过期未激活、过期未使用将导致优惠券无法使用，请及时激活并使用。
      </p>
      <p>
        3，同一个微信只可激活一张代金券。另外，相同实名认证的手机号的不同微信也不可激活多张代金券。
      </p>
      <p>
        4，生活权益会员每个月只能享受20元商超代金券、互联网会员权益、中石油加油券、特惠充值券权益中的一种，同一个自然月内不可享受两种权益。
      </p>
      <p>
        5，每月1日、2日全天以及每天的00:00-08:00为系统维护时间，维护期间无法领券。
      </p>
      <p>
        6，特惠充值服务由于优惠折扣较大，充值到账时间为0-72小时。

      </p>
      <p>
        7，兑换特惠充值券后，充值服务由掌讯信息小程序提供，与 移动无关。

      </p>
      <p>8，目前特惠充值服务仅支持给会员账号充值，且不支持携转号码的充值。</p>

      <p>
        9，充值过程中遇到问题请联系掌讯信息客服，充值客服电话：15004710036（微信同号）。

      </p>
    </div>
    <div @click="submits" style="text-align: center;position: fixed;bottom: -0.5rem; width: 100%;">
      <img style="width: 75%;" src="../../assets/img/zgsy/ljlq.png" />
    </div>
  </div>
</template>

<script>
import {
  getCouponList,
  getuserInfo,
  recieveCnpcRights,
  getReceiveCoupon,
} from "@/api/user";
import { mapState } from "vuex";
import { getItem, setItem } from "@/utils/storage";
import { Toast, Dialog } from "vant";
import { loadbmap } from "@/api/loadbmap";
export default {
  name: "home",

  props: {},
  data() {
    return {
      isLoading: false,
      userType: "3",
      active: 0, //被激活的列表
      show: false,
      loading: false,
      finished: false,
      detailid: null,
      from: 0,
      value: "",
      city: "",
      areaId: "", //城市id
      goodslist: [], //商品列表
      longitude: "", //经度
      couponType: "",
      searchWord: "",
      latitude: "", //纬度
      channels: [], //频道列表
      isChannelEditShow: false, //频道编辑层
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  watch: {},
  created() {
    // this.loadChannels()
  },
  mounted() {
    this.geturl();
    this.onloads();
    this.record({
      type: 6,
      resourceId: "",
    });
  },
  methods: {
    onloads() {
      const _this = this;
      const city = "";
      this.show = getItem("show");
      // setItem('show',false)
      if (getItem("latitude") == "" || getItem("latitude") == null) {
        this.$router.push({
          name: "",
        });
      } else {
        // this.city = getItem('localtion').name
        this.city = "滨州市";
        this.userinfo();
      }
    },

    geturl() {
      try {
        var url = window.location.href; //获取当前url
        // var dz_url = url.split('#')[1]; //获取#/之后的字符串
        var dz_url = url.split("#")[0]; //获取#/之前的字符串
        var cs = dz_url.split("?")[1]; //获取?之后的参数字符串
        console.log(cs);
        var cs_arr = cs.split("&"); //参数字符串分割为数组
        var cs = {};
        for (var i = 0; i < cs_arr.length; i++) {
          //遍历数组，拿到json对象
          cs[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
        }
        this.detailid = decodeURIComponent(cs.busiStoreCouponTypeId);
      } catch (e) {
        this.detailid = null;
        //TODO handle the exception
      }
    },
    async submits() {
      let params = {
        //   "userId":12,
        busiStoreCouponTypeId: this.detailid,
      };
      if (this.detailid == null) {
        Dialog.alert({
          title: "",
          message: "请重新选择优惠卷",
        }).then((res) => {
          this.$router.push({
            name: "home",
          });
        });
      }
      Dialog.confirm({
        title: "温馨提示",
        message:
          "话费券仅支持给会员本机号码充值，不支持携转号的充值。携转号会员请勿领取。\n1.由于充值渠道充值失败率过高，对新用户暂停开放，若您是在2023年5月20日后开通的会员，将无法领取本充值券。\n2.老用户也请慎重领取，目前充值渠道依然不稳定。",
        confirmButtonText: "确认领取",
        cancelButtonText: "放弃",
        confirmButtonColor: "#FFFFFF",
        messageAlign: "left",
        className: "czq_confirm",
      })
        .then(async () => {
          const { data } = await getReceiveCoupon(params);
          if (
            data.code == "100102" ||
            data.code == "100103" ||
            data.code == "100104" ||
            data.code == "100105"
          ) {
            Dialog.alert({
              title: "",
              showCancelButton: true,
              confirmButtonText: "立即开通",
              message: "您尚未开通会员，无法领取！",
            }).then(() => {
              this.$router.push({
                name: "member",
                params: "",
              });
            });
          } else {
            Dialog.alert({
              title: "",
              confirmButtonText: "确定",
              message: data.msg,
            });
          }
        })
        .catch(() => { });
    },
    selecthref(href, data = "") {
      if (this.userType == 1) {
        return;
      }
      this.$router.push({
        name: href,
        params: data,
      });
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
      this.from = 1;
      this.goodslist = [];
      this.searchWord = "";
      this.show = false;
      this.getlocation();
    },

    async userinfo() {
      const { data } = await getuserInfo();

      this.userType = data.data.userType;
      // this.finished=true
    },
    async loadChannels() {
      const { data } = await getSelectBusiSearchHotWordList();
      this.channels = data.data;
      console.log(this.channels);
    },

    // onUpdateActive(index) {
    //   this.active = index
    // }
    async onSearch(value) {
      console.log(value);

      this.value = value;
      this.from = 0;
      this.searchWord = value;
      this.goodslist = [];
      this.loading = true;
      this.finished = false;
      await this.CouponList();
      this.show = !this.show;
    },
    selecthrefs(val) {
      window.location.href = val;
    },

    // onUpdateActive(index) {
    //   this.active = index
    // }
  },
};
</script>

<style scoped lang="less">
.goodstext p {
  margin: 0.15rem;
}

.my-swipe .van-swipe-item {
  width: 100%;
  height: 3rem;
  // color: #fff;
  // font-size: 20px;
  // line-height: 150px;
  // text-align: center;
  // background-color: #39a9ed;

  img {
    width: 100%;
    height: 100%;
  }
}

.van-search--show-action {
  padding: 0;
  margin: 0 0.2rem;
  border-radius: 10rem !important;
}

.van-search__action {
  padding: 0;
}

.van-search {
  width: 72%;
  background-color: #f7f8fa;
}

.home-container {
  width: 100%;
  // height: 5rem;
  background: linear-gradient(90deg, #ff4e6b, #ff2f47);
  padding-bottom: 2rem;

  img {
    // pointer-events: none;
    width: 100%;
  }

  .div_img {
    width: 95%;
    margin: 0 auto;
    padding-top: 0.25rem;
  }

  .index-nav {
    font-size: 0.3rem;
    font-weight: 700;

    img {
      height: 0.8rem;
      width: 0.8rem;
    }

    span {
      padding-top: 0.15rem;
      color: #333333;
    }
  }

  .index-banner {
    width: 88%;
    margin: 0 auto;
    background: #fff;
    margin-top: 0.3rem;
    font-size: 0.4rem;
    color: #333333;
    font-weight: 700;
    padding: 0.3rem;
    padding-bottom: 0;
    position: relative;

    div {
      margin-bottom: 0.2rem;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .list-title {
    background-image: url(../../assets/img/navs.png);
    font-size: 0.4rem;
    width: 100%;
    height: 1.2rem;
    background-size: 100% 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    padding-right: 0.5rem;

    span {
      display: block;
      padding-top: 0.3rem;
      font-weight: 700;
      padding-left: 0.3rem;
    }
  }

  .list-content {
    border-radius: 0.2rem;
    margin-bottom: 0.3rem;
    display: flex;
    background: #fff;
    padding: 0.5rem;

    img {
      display: block;
      // width: 1.8rem;
      height: 2rem;
    }
  }

  .list-info {
    padding-left: 0.5rem;

    span {
      display: block;
      font-size: 0.3rem;

      &:nth-child(1) {
        font-size: 0.4rem;
        color: #333333;
        font-family: SourceHanSansCN-Regular;
      }

      &:nth-child(2) {
        padding: 0.1rem 0;
        font-size: 0.3rem;
        background: #ffe9e9;
        border-radius: 0.3rem;
        text-align: center;
        margin: 0.28rem;
        width: 2.5rem;
        color: #e60027;
        margin-left: 0;
      }

      &:nth-child(3) {
        font-size: 0.3rem;
        color: #999999;
      }
    }
  }

  .index-bottom {
    /* margin: 0rem 0px; */
    width: 90%;
    background: #fff;
    margin: 0 auto;
    font-size: 0.4rem;
    /* margin-top: 0.1rem; */
    margin-bottom: 2rem;
    padding: 0.5rem;
    text-align: center;
    border-radius: 0.2rem;
  }

  /deep/ .van-nav-bar__title {
    max-width: none;
  }

  .search-btn {
    width: 277px;
    height: 32px;
    background: #5babfb;
    border: none;

    .van-icon {
      font-size: 16px;
    }

    .van-button__text {
      font-size: 14px;
    }
  }
}
</style>
